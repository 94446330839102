import app from '@/utils/vue.js';
import '@/utils/styles/reset.less';
import '@/utils/styles/tailwindcss.css';
import '@/utils/http';
import 'animate.css';
import 'animate.css/animate.compat.css';
const meta = document.createElement('meta');
meta.name = 'naive-ui-style';
document.head.appendChild(meta);

app.mount('#app');
