import routes from './routes';
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';

const router = createRouter({
  // 使用hash模式 可直接访问 不需部署spa
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// 拦截push异常
const originalPush = router.push;
router.push = function (location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 拦截replace异常
const originalReplace = router.replace;
router.replace = function (location) {
  return originalReplace.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  // 路由跳转时 取消所有旧请求
  // axios.prototype.axiosPromiseArr.forEach((controller, index) => {
  //   controller.abort();
  //   delete axios.prototype.axiosPromiseArr[index];
  // });
  let token = localStorage.getItem('token');
  if (token) {
    if (to.matched.some((record) => record.meta.loginBefore)) {
      next('/tabbar/home');
      return;
    }
  } else {
    if (to.matched.some((record) => record.meta.loginAfter)) {
      if (from.matched.some((record) => record.path === '/login')) {
        next(false);
        return;
      } else {
        next('/login');
        return;
      }
    }
  }
  next();
});

router.afterEach((to, from, next) => {});

export default router;
