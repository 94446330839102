export default {
  出国留学攻略: '出国留学攻略',
  留学指南: '留学指南',
  留学解析: '留学解析',
  国际学校留学: '国际学校留学',
  英国本科留学申请: '英国本科留学申请',
  美国本科留学申请: '美国本科留学申请',
  英国硕士留学申请: '英国硕士留学申请',
  国际学校择校: '国际学校择校',
  在线客服: '在线客服',
  电话咨询: '电话咨询',
  全国统一服务热线: '全国统一服务热线',
  点击马上给您回电: '点击马上给您回电',
  成功提交后我们将尽快与您联系: '成功提交后我们将尽快与您联系，请注意来电哦！',
  微信咨询: '微信咨询',
  费用查询: '费用查询',
  课程报价查询系统: '课程报价查询系统',
  立即查询: '立即查询',
  立即预约: '立即预约',
  试听预约: '试听预约',
  请选择您想试听的课程: '请选择您想试听的课程',
  姓名: '姓名',
  lang: '中文',
  立即咨询: '立即咨询',
  手机号码格式不正确: '手机号码格式不正确',
  phone: '电话',
  首页: '首页',
  课程: '课程',
  关于我们: '关于我们',
  'A-LeveI课程': 'A-LeveI课程',
  背景提升: '背景提升',
  雅思语培: '雅思语培',
  留学申请: '留学申请',
  干货资料: '干货资料',
  脱产全日制: '脱产全日制',
  电话: '电话',
  QQ号: 'QQ号',
  邮箱: '邮箱',
  帮助体制内外30W中国学子步入世界百强院校: '帮助体制内外30W中国学子步入世界百强院校',
  雅思课程: '雅思课程',
  留学规划: '留学规划',
  申请服务: '申请服务',
  闭环交付: '闭环交付',
  全日制方案制定: '全日制方案制定',
  学员案例: '学员案例',
  录取案例: '录取案例',
  干货视频分享: '干货视频分享',
  姓名: '姓名',
  提交: '提交',
  校区展示: '校区展示',
  提交不能为空: '提交不能为空',
  国际竞赛: '国际竞赛',
  学科竞赛: '学科竞赛',
  学科竞赛1: 'AMC、滑铁卢大学国际数学竞赛、BMO、MAT、STEP、 PAT、Physics Bowl等竞赛辅导。',
  学科竞赛2: '收获：丰富CV履历；学术实力提升；增加专业录取概率；增加奖学金申请概率等。',
  国际竞赛时间: '各类国际竞赛时间',
  科研论文: '科研论文',
  科研项目: '科研项目',
  科研项目1: '通过参与世界名校科研项目，有效提升申请者科研背景，并获得由世界名校提供的推荐信，极大提高科研背景和申请成功率。',
  科研项目2: '收获：顶级名校学者亲笔推荐信；提升口语表达能力和项目汇报能力；团队协作能力等。',
  '教学/论文服务流程': '教学/论文服务流程',
  精品实习: '精品实习',
  精品实习1: '名企数据分析、金融、会计、市场营销、运营、开发、物流供应链等高含金量实习岗位。深度参与项目，全面提升申请者软实力。',
  精品实习2: '收获：世界名企实习经历；全方位了解世界名企工作者的日常；世界名企高管亲笔推荐信等。',
  夏校游学: '夏校游学',
  国际会议论文单项服务: '国际会议论文单项服务',
  'EI/SCOPUS期刊论文（全程辅导版）': 'EI/SCOPUS期刊论文（全程辅导版）',
  远程: '远程',
  一对一指导: '一对一指导',
  '全科覆盖，文/理/工/商/计算机': '全科覆盖，文/理/工/商/计算机',
  '量身定题，匹配教授，选题自由': '量身定题，匹配教授，选题自由',
  独立第一作者: '独立第一作者',
  '权威期刊，专业保障硬产出': '权威期刊，专业保障硬产出',
  成果: '成果',
  导师: '导师',
  周期: '周期',
  服务: '服务',
  '选、学、读、思、行、写，全程辅导': '选、学、读、思、行、写，全程辅导',
  独立研究项目: '独立研究项目',
  独立研究项目1: `International EPQ项目（OxfordAQA International Extended Project Qualification）是牛津AQA考试局专为国际高中生设置的研究性学习认证。International EPQ旨在通过项目制学习培养学生的独立研究能力，没有专业限制、不需要考试，强调学生从自身兴趣出发选择课题方向，在导师的指导下展开深度研究。`,
  独立研究项目2: `导师最终根据过程日志、5000字英文项目报告（或作品集+1000字英文项目报告）、答辩为学生评定成绩等级，授予OxfordAQA International EPQ证书。学生在完成项目课题研究和项目报告后，最高可以获得UCAS 28分的Tariff Points的加分，为冲击牛津、剑桥等G5高校提供最直接有效的助力!`,
  独立研究项目3: `International EPQ项目现已获得世界名校的广泛认可，包括罗素大学集团(RusseI Group)、纽约大学以及诸多世界名校都明确表示欢迎拥有 International EPQ认证的学生申请。`,
  如何助力申请: '如何助力申请',
  如何助力申请1: `换取UCAS分数 助力录取`,
  如何助力申请2: `顺利完成International EPQ项目的学生最高可获得UCAS 28分的Tariff
  Points加分。`,
  如何助力申请3: `研究性学习过程 助力标化成绩提升`,
  如何助力申请4: `参与International EPQ项目的学生容易取得更高等级的标化成绩。据考试局发布的官方数据显示，获得International
  EPQ认证的学生在 A-level考试中拿到A* -B级的可能性提高了29％。`,
  如何助力申请5: `标化成绩之外 差异化竞争优势`,
  如何助力申请6: `通过参与International EPQ研究性学习认证，在申请过程中，学生将能够以实际案例证明自己拥有大学所关注的各项能力。`,
  如何助力申请7: `适应研究性学习 为大学生活做好准备`,
  如何助力申请8: `International EPQ研究性学习认证传承牛津大学百年的治学标准，帮助学生成为被优质院校青睐的优秀申请者的同时，培养学生大学阶段所必须的研究性学习能力和综合素质，让学生在高强度的海外学习中争取做到游刃有余。`,
  更受优质院校认可: '更受优质院校认可',
  项目优势: '项目优势',
  项目优势1: `公平的竞争平台`,
  项目优势2: `International EPQ可选择母语答辩，对于国际学生更友好，可创造更公平的竞争条件`,
  项目优势3: `清晰的授课目标`,
  项目优势4: `明确的授课内容及目标，确保学生系统掌握大学学习阶段必备的科研方法`,
  项目优势5: `明确的产出要求`,
  项目优势6: `学生需输出一篇5000字英文项目报告或作品集+1000字英文项目报告`,
  项目优势7: `明晰的评审标准`,
  项目优势8: `明晰的打分流程指导及标准要求，保证打分标准的公平一致`,
  哪些学生适合参与: '哪些学生适合参与',
  作为英国知名的研究能力认证: `作为英国知名的研究能力认证，International EPQ所考核的核心能力以及国际学生友好的学习要求，是全世界名校都关注的，适宜中外任何课程体系(包括但不仅限于 A-level、AP、IBDP、加拿大BC、澳洲ACENCE/WACE、国内课程等)，申请各国大学的高一、高二学生均可参与(就读于英国本土高中的学生除外)，可作为个人能力的权威证明与面试素材。`,
  申请英国方向: '申请英国方向',
  在申请系统中可提交: `在申请系统中可提交 International EPQ认证及成绩，证明个人适应英国科研能力培 养体系。`,
  申请美国及其它国家方向: `申请美国及其它国家方向`,
  申请美国及其它国家方向1: `可提交International EPQ认证 作为辅助材料，证明自己拥有牛津AQA考试局所认证的研究 性学习能力。`,
  参与中国高考: `参与中国高考`,
  参与中国高考1: `新高考环境下，International EPQ可作为综合评价及强基计划中的支持材料，证明个人研 究性学习能力。`,
  往期课题参考: `往期课题参考`,
  为何选择学习中心: `为何选择International EPQ学习中心`,
  学习中心专家委员会成员: `International EPQ学习中心专家委员会成员（部分）`,
  学习中心教研人员展示: `International EPQ学习中心教研人员展示（部分）`,
  国际课程: `国际课程`,
  团队介绍: `团队介绍`,
  创始人: `创始人`,
  部门组成: `部门组成`,
  团队: `团队`,
  教师团队: `教师团队`,
  更多师资请咨询帝联国际教育: `更多师资请咨询帝联国际教育`,
  后端交付团队: `后端交付团队`,
  雅思英语团队: `雅思英语团队`,
  留学申请团队: `留学申请团队`,
  文书老师: `文书老师`,
  顾问团队: `顾问团队`,
  新媒体部门: `新媒体部门`,
  校区运营团队: `校区运营团队`,
  帝联发展历程: `帝联发展历程`,
  '帝联·使命&愿景&价值观': `帝联·使命&愿景&价值观`,
  '课程&申请咨询': `课程&申请咨询`,
  课程3: `的全称是英国高中课程（General Certificate of Education Advanced Level）,它是英国的全民课程体系，也是英国学生的大学入学考试课程，相当于英国高考。A-Level课程适用于初中毕业及以上的中学生，在中国开设A-Level课程旨在为中国学生提供进入国外大学的有效途径。结合中国学生学习特点和中国基础教育优势，A-Level课程一般在中国开设基础数学、进阶数学（或称高等数学）、物理、化学、会计、经济学等课程供学生选择。`,
  我能去哪里: `通过A-Level课程我能去哪里？`,
  英国: `英国`,
  中国香港: `中国香港`,
  加拿大: `加拿大`,
  澳大利亚: `澳大利亚`,
  a1: `几乎所有英语授课的国家及地区均承认A-Level课程证书。若拥有
  不错的A-Level成绩，则可以直接申请就读世界名校大一。`,
  爱尔兰: `爱尔兰`,
  新加坡: `新加坡`,
  南非: `南非`,
  美国: `美国`,
  课程介绍: `课程介绍`,
  教服团队: `教服团队`,
  主讲老师团队: `主讲老师团队`,
  以购课后匹配老师为准: `以购课后匹配老师为准`,
  课程福利: `课程福利`,
  授课方式: `授课方式`,
  线上授课: `线上授课`,
  '线下授课/OMO双师课堂': `线下授课/OMO双师课堂`,
  上课模式及流程: `上课模式及流程`,
  课前预习: `课前预习`,
  '作业布置批改+课后答疑': `作业布置批改+课后答疑`,
  课中指定内容讲解: `课中指定内容讲解`,
  '课后录播回放+课堂板书及学习资料发送': `课后录播回放+课堂板书及学习资料发送`,
  课后答疑: `课后答疑`,
  帝联国际教育优势: `帝联国际教育优势`,
  优势: `优势`,
  学习体系: `学习体系`,
  留学申请流程: `留学申请流程`,
  申请团队: `申请团队`,
  热门留学国家: `热门留学国家`,
  成功案例: `成功案例`,
  文书示例: `文书示例`,
  帝联跃迁计划: `帝联跃迁计划`,
  全程4v1教服: `全程4v1教服`,
  全日制课程介绍: `全日制课程介绍`,
  涵盖科目: `涵盖科目`,
  授课流程: `授课流程`,
  适合学员: `适合学员`,
  课程优势: `课程优势`,
  课程保障: `课程保障`,
  部分出分案例: `部分出分案例`,
  雅思课程设置: `雅思课程设置`,
  雅思课程优势: `雅思课程优势`,
  接受雅思成绩的大学: `接受雅思成绩的大学`,
};
