import { defineStore } from 'pinia';

function getDefaultState() {
  return {
    userInfo: {},
    lang: 'zh',
  };
}
export const useStore = defineStore('base', {
  state: getDefaultState,
  actions: {},
});
