import { createApp } from 'vue';
import App from '@/App.vue';
import { createPinia } from 'pinia';
import router from '@/router/index.js';
import components from '@/components/index.js';
import i18n from '@/locals';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

// Toast
import { showToast } from 'vant';
import 'vant/es/toast/style';
window.$showToast = showToast;
window.$message = showToast;

// Dialog
import { showDialog } from 'vant';
import 'vant/es/dialog/style';
window.$showDialog = showDialog;

// Notify
import { showNotify } from 'vant';
import 'vant/es/notify/style';
window.$showNotify = showNotify;

// ImagePreview
import { showImagePreview } from 'vant';
import 'vant/es/image-preview/style';
window.$showImagePreview = showImagePreview;

const app = createApp(App);

app.use(router);

const pinia = createPinia();
app.use(pinia);

app.use(components);

app.use(i18n);

app.use(ElementPlus, {
  locale: zhCn,
});
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

import * as commonAll from '@/utils/common';
for (let [name, value] of Object.entries(commonAll)) {
  app.config.globalProperties[`$${name}`] = value;
  window[`$${name}`] = value;
}
export default app;
