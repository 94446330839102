import axios from 'axios';
import { logout } from '@/utils/common';
import { debounce } from 'lodash';
import qs from 'qs';
import env from '@/utils/env';

axios.prototype.axiosPromiseArr = [];
axios.defaults.timeout = 1000 * 60 * 60 * 5;
// axios.defaults.baseURL = env.base;

axios.interceptors.request.use(
  (config) => {
    let data = qs.parse(config.data);
    if (config.data instanceof FormData) {
      config.data.forEach((value, key) => (data[key] = value));
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
// 信息提示函数
const messageSelf = debounce(
  (message) => {
    setTimeout(() => {
      window.$message.error(message);
    }, 1000);
  },
  1000,
  { leading: true, trailing: false },
);
axios.interceptors.response.use(
  (res) => {
    if (res.data.code !== 0) {
      if (res.data.code === 401) {
        if (localStorage.getItem('token')) {
          if (res.data.msg) {
            messageSelf(res.data.msg);
          } else {
            messageSelf('登录状态已失效, 请重新登录');
          }
          logout({
            delMac: false,
          });
        }
        let audio = document.getElementById('globalAudio');
        audio?.pause();
        return Promise.reject();
      }
      if (res.data.code === 402) {
        logout({
          delMac: false,
        });
        messageSelf('此账号已在别处登录，如不是本人操\n作，请尽快修改密码');
        return Promise.reject();
      }
    }
    return res.data;
  },
  (error) => {
    try {
      if (error.request.readyState === 4) {
        if (error.request.status === 0) {
          // messageSelf('网络错误');
        }
        if (error.request.status === 500) {
          // messageSelf('请求中断, 请稍后再试');
        }
      }
    } catch (error) {}
    return Promise.reject(error);
  },
);
