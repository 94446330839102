export default [
  {
    path: '/',
    alias: '',
    component: () => import('@/pages/layout.vue'),
    children: [
      {
        path: '/home',
        alias: '',
        component: () => import('@/pages/home.vue'),
      },
      {
        path: '/gryu',
        component: () => import('@/pages/gryu.vue'),
      },
      {
        path: '/keig',
        component: () => import('@/pages/keig.vue'),
      },
      {
        path: '/bzjl',
        component: () => import('@/pages/bzjl.vue'),
      },
      {
        path: '/yupz',
        component: () => import('@/pages/yupz.vue'),
      },
      {
        path: '/lqxt',
        component: () => import('@/pages/lqxt.vue'),
      },
      {
        path: '/gjho',
        component: () => import('@/pages/gjho.vue'),
      },
      {
        path: '/toij',
        component: () => import('@/pages/toij.vue'),
      },
    ],
  },
];
