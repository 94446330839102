export default {
  出国留学攻略: 'Guide to studying abroad',
  留学指南: 'Study Abroad Guide',
  留学解析: 'Study abroad analysis',
  国际学校留学: 'Study abroad in international schools',
  英国本科留学申请: 'Application for Undergraduate Study Abroad in the UK',
  美国本科留学申请: 'Application for Undergraduate Study Abroad in the United States',
  英国硕士留学申请: 'Application for Masters Study Abroad in the UK',
  国际学校择校: 'International school selection',
  在线客服: 'online service',
  电话咨询: 'Telephone consultation',
  全国统一服务热线: 'Unified national service hotline',
  点击马上给您回电: 'Click to call you back immediately',
  成功提交后我们将尽快与您联系: 'We will contact you as soon as possible after successful submission, please pay attention to your call!',
  微信咨询: 'WeChat consultation',
  费用查询: 'Cost inquiry',
  课程报价查询系统: 'Course quotation inquiry system',
  立即查询: 'Inquire now',
  立即预约: 'Reservation Now',
  试听预约: 'Audition appointment',
  请选择您想试听的课程: 'Please select the course you want to try',
  姓名: 'Name',
  lang: 'English',
  立即咨询: 'Consult now',
  手机号码格式不正确: 'phone error',
  课程: 'course',
  phone: 'Telephone',
  首页: 'front page',
  关于我们: 'about Us',
  背景提升: 'background boost',
  雅思语培: 'IELTS language training',
  留学申请: 'Study Abroad Application',
  干货资料: 'Dry information',
  脱产全日制: 'Full-time off-duty work',
  电话: 'Telephone',
  QQ号: 'QQ number',
  邮箱: 'Mail',
  帮助体制内外30W中国学子步入世界百强院校: 'Help 300,000 Chinese students inside and outside the system enter the worlds top 100 universities',
  雅思课程: 'IELTS course',
  留学规划: 'Study Abroad Planning',
  申请服务: 'Apply for service',
  闭环交付: 'Closed loop delivery',
  全日制方案制定: 'Full-time program development',
  学员案例: 'Student Case',
  录取案例: 'Admission case',
  干货视频分享: 'Video sharing of useful information',
  姓名: 'Name',
  提交: 'submit',
  校区展示: 'Campus display',
  提交不能为空: 'Submit cannot be empty',
  国际竞赛: 'international competition',
  学科竞赛: 'subject competition',
  学科竞赛1: 'AMC, University of Waterloo International Mathematics Competition, BMO, MAT, STEP, PAT, Physics Bowl and other competition coaching. ',
  学科竞赛2: 'Gains: Enrich your CV; improve your academic strength; increase your probability of major admissions; increase your probability of applying for scholarships, etc. ',
  国际竞赛时间: 'international competition time',
  科研论文: 'Research Papers',
  科研项目: 'Scientific research project',
  科研项目1:
    'By participating in scientific research projects of world-renowned universities, applicants can effectively improve their scientific research background, and obtain letters of recommendation from world-renowned universities, which greatly improves their scientific research background and application success rate. ',
  科研项目2: 'Gains: Personal recommendation letters from scholars from top universities; improved oral expression and project reporting skills; teamwork skills, etc. ',
  '教学/论文服务流程': 'Teaching/thesis service process',
  精品实习: 'Excellent internship',
  精品实习1:
    'High-value internship positions in data analysis, finance, accounting, marketing, operations, development, logistics supply chain, etc. at famous companies. Participate deeply in the project and comprehensively improve the soft power of applicants.',
  精品实习2:
    'Gains: Internship experience in world-famous companies; comprehensive understanding of the daily life of workers in world-famous companies; personal recommendation letters from executives of world-famous companies, etc.',
  夏校游学: 'summer school tour',
  国际会议论文单项服务: 'International conference paper single service',
  'EI/SCOPUS期刊论文（全程辅导版）': 'EI/SCOPUS journal papers (full tutorial version)',
  远程: 'Remotely',
  一对一指导: 'One-to-one guidance',
  '全科覆盖，文/理/工/商/计算机': 'Covering all subjects, arts/science/industry/business/computer',
  '量身定题，匹配教授，选题自由': 'Tailor-made topics, matching professors, free choice of topics',
  独立第一作者: 'Independent first author',
  '权威期刊，专业保障硬产出': 'Authoritative journal, professional guarantee of hard output',
  成果: 'Achievements',
  导师: 'tutor',
  周期: 'cycle',
  服务: 'Serve',
  '选、学、读、思、行、写，全程辅导': 'Select, study, read, think, act and write, full guidance',
  独立研究项目: 'independent research project',
  独立研究项目1: `International EPQ Project (OxfordAQA International Extended Project
    Qualification) is a research-based learning certification set by the Oxford AQA examination board specifically for international high school students.
    International EPQ aims to cultivate students’ independent research capabilities through project-based learning.
    There are no major restrictions and no examination required. It is emphasized that students choose the subject direction based on their own interests and follow the guidance of the instructor.
    Conduct in-depth research under guidance.`,
  独立研究项目2: `The instructor will finally submit the final report based on the process log and 5,000-word English project report (or portfolio + 1,000-word English
    Project report), defense assessment for students, awarded to OxfordAQA International
    EPQ certificate. Students can earn up to 28 UCAS points after completing project research and project reports.
    The extra points of Tariff Points provide the most direct and effective assistance for entering G5 universities such as Oxford and Cambridge!`,
  独立研究项目3: `The International EPQ program has now been widely recognized by world-famous universities, including Russell University
  The Russel Group, New York University and many world-famous universities have made it clear that they welcome
  International EPQ certified students apply.`,
  如何助力申请: 'How to help apply',
  如何助力申请1: `Exchange UCAS points to help with admissions`,
  如何助力申请2: `Students who successfully complete the International EPQ project can receive up to 28 UCAS TariffPoints.`,
  如何助力申请3: `Research-based learning process helps improve standardized scores`,
  如何助力申请4: `Students participating in the International EPQ project can easily obtain more
  High-level standardized scores. According to official data released by the examination board, those who obtained the International
  EPQ-certified students are 29% more likely to achieve A*-B grades at A-level exams.`,
  如何助力申请5: `Differentiated competitive advantages beyond standardized scores`,
  如何助力申请6: `By participating in the International EPQ research-based learning certification,
  During the application process, students will be able to demonstrate their
  Have all the abilities that universities focus on.`,
  如何助力申请7: `Adapt to research studies and prepare for university life`,
  如何助力申请8: `International EPQ Research-Based Learning Certification Heritage Oxford University
  Centuries of academic standards help students become favored by high-quality colleges and universities
  While recruiting excellent applicants, we also train students with the necessary skills at the university level.
  research-based learning ability and comprehensive quality, allowing students to learn in high-intensity
  Strive to be comfortable while studying abroad.`,
  更受优质院校认可: 'More recognized by high-quality colleges and universities',
  项目优势: 'Project Benefits',
  项目优势1: `level playing field`,
  项目优势2: `International EPQ allows you to choose to defend yourself in your mother tongue, which is more friendly to international students and creates fairer competition conditions.`,
  项目优势3: `Clear teaching objectives`,
  项目优势4: `Clear teaching content and objectives ensure that students systematically master the scientific research methods necessary for university study.`,
  项目优势5: `clear output requirements`,
  项目优势6: `Students need to output a 5,000-word English project report or a portfolio + 1,000-word English project report`,
  项目优势7: `Clear evaluation criteria`,
  项目优势8: `Clear scoring process guidance and standard requirements to ensure fairness and consistency of scoring standards`,
  哪些学生适合参与: 'Which students are suitable to participate?',
  作为英国知名的研究能力认证: `As a well-known research ability certification in the UK, International
  Core competencies assessed by EPQ and international students
  Friendly learning requirements are the focus of prestigious schools around the world and are suitable for any curriculum system at home and abroad (including but not limited to
  A-level, AP, IBDP, Canadian BC, Australian ACENCE/WACE, domestic courses, etc.), apply for universities in various countries
  All first and second grade students studying in high school can participate (except students studying in British high schools), and it can be used as a personal ability
  Authoritative certification and interview materials.`,
  申请英国方向: 'Apply for UK direction',
  在申请系统中可提交: `International EPQ certification and scores can be submitted in the application system to prove that individuals adapt to the British scientific research ability training system.`,
  申请美国及其它国家方向: `Apply for directions in the United States and other countries`,
  申请美国及其它国家方向1: `You can submit the International EPQ certification as auxiliary materials to prove that you have research-based learning abilities certified by the Oxford AQA Examination Board.`,
  参与中国高考: `Participate in China’s College Entrance Examination`,
  参与中国高考1: `Under the new college entrance examination environment, International EPQ can be used as supporting material in comprehensive evaluation and foundation strengthening plans to prove personal research learning ability.`,
  往期课题参考: `References to past topics`,
  为何选择学习中心: `Why Choose International EPQ Learning Center`,
  学习中心专家委员会成员: `International EPQ Learning Center Expert Committee Member (Part)`,
  学习中心教研人员展示: `International EPQ Learning Center teaching and research staff display (part)`,
  国际课程: `international courses`,
  团队介绍: `team introduction`,
  创始人: `Founder`,
  部门组成: `Department composition`,
  团队: `team`,
  教师团队: `Teacher team`,
  更多师资请咨询帝联国际教育: `For more teachers, please contact Dilian International Education`,
  后端交付团队: `Backend delivery team`,
  雅思英语团队: `IELTS English Team`,
  留学申请团队: `Study Abroad Application Team`,
  文书老师: `clerical teacher`,
  顾问团队: `Advisory Team`,
  新媒体部门: `New media department`,
  校区运营团队: `Campus Operations Team`,
  帝联发展历程: `The development history of Dilian`,
  '帝联·使命&愿景&价值观': `Dilian·Mission&Vision&Values`,
  '课程&申请咨询': `Courses & Application Consulting`,
  课程3: `The full name is the British High School Course (General Certificate of Education Advanced Level). It is the British national curriculum system and the university entrance examination course for British students, which is equivalent to the British College Entrance Examination. A-Level courses are suitable for middle school students who have graduated from junior high school and above. The purpose of opening A-Level courses in China is to provide Chinese students with an effective way to enter foreign universities. Combining the learning characteristics of Chinese students and China's basic education advantages, A-Level courses generally offer basic mathematics, advanced mathematics (or advanced mathematics), physics, chemistry, accounting, economics and other courses in China for students to choose from.`,
  我能去哪里: `Where can I go with my A-Levels?`,
  英国: `U.K.`,
  中国香港: `China Hong Kong`,
  加拿大: `Canada`,
  澳大利亚: `Australia`,
  a1: `Almost all countries and regions where English is taught recognize A-Level course certificates. If you have good A-Level scores, you can directly apply to attend a world-renowned university as a freshman.`,
  爱尔兰: `Ireland`,
  新加坡: `Singapore`,
  南非: `South Africa`,
  美国: `USA`,
  课程介绍: `Course Introduction`,
  教服团队: `Teaching team`,
  主讲老师团队: `Lecturer team`,
  以购课后匹配老师为准: `Subject to matching teacher after purchasing the course`,
  课程福利: `Course benefits`,
  授课方式: `Teaching methods`,
  线上授课: `Online teaching`,
  '线下授课/OMO双师课堂': `Offline teaching/OMO dual teacher classroom`,
  上课模式及流程: `Class mode and process`,
  课前预习: `Preview before class`,
  '作业布置批改+课后答疑': `Homework assignment correction + after-class Q&A`,
  课中指定内容讲解: `Explanation of designated content in class`,
  '课后录播回放+课堂板书及学习资料发送': `Post-class recording and playback + classroom blackboard writing and learning materials delivery`,
  课后答疑: `Q&A after class`,
  帝联国际教育优势: `Dilian International Education Advantages`,
  优势: `Advantage`,
  学习体系: `learning system`,
  留学申请流程: `Study abroad application process`,
  申请团队: `Application team`,
  热门留学国家: `Popular study abroad countries`,
  成功案例: `success case`,
  文书示例: `Document Example`,
  帝联跃迁计划: `Imperial Alliance Jump Plan`,
  全程4v1教服: `Full 4v1 teaching service`,
  全日制课程介绍: `Full-time course introduction`,
  涵盖科目: `Subjects covered`,
  授课流程: `Teaching process`,
  适合学员: `Suitable for students`,
  课程优势: `Course advantages`,
  课程保障: `Course guarantee`,
  部分出分案例: `Some cases of scoring points`,
  雅思课程设置: `IELTS course settings`,
  雅思课程优势: `IELTS course advantages`,
  接受雅思成绩的大学: `Universities that accept IELTS scores`,
};
