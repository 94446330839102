<template>
  <n-config-provider class="pageFull" :key="langKey">
    <div class="pageFull" :key="autoWidthKey" :data-width="autoWidthKey">
      <el-config-provider :locale="elLocale">
        <router-view />
      </el-config-provider>
    </div>
  </n-config-provider>
</template>

<script setup>
import { NConfigProvider, zhCN, dateZhCN } from 'naive-ui';
import { watchEffect, ref, onMounted, watch, onUnmounted } from 'vue';
import { useStore } from '@/store';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import WOW from 'wow.js';
const { t, locale } = useI18n();

onMounted(() => {
  new WOW().init({
    boxClass: 'wow', // animated element css class (default is wow)
    animateClass: 'animate__animated', // animation css class (default is animated)
    offset: 0, // distance to the element when triggering the animation (default is 0)
    mobile: true, // trigger animations on mobile devices (default is true)
    live: true, // act on asynchronously loaded content (default is true)
    callback: function (box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    },
    scrollContainer: null, // optional scroll container selector, otherwise use window,
    resetAnimation: true, // reset animation on end (default is true)
  });
});

const store = useStore();

const elLocale = ref(zhCn);

let langKey = ref('');
watch(
  () => store.lang,
  () => {
    if (store.lang && store.lang !== locale.value) {
      locale.value = store.lang;
      localStorage.setItem('lang', store.lang);
      langKey.value = Date.now();
    }
  },
  {
    deep: true,
  },
);

onMounted(() => {
  let lang = localStorage.getItem('lang');
  if (lang && lang !== store.lang) {
    store.lang = lang;
  }
});

// store数据持久化
watch(
  () => store,
  () => {
    localStorage.setItem('store', JSON.stringify(store.$state));
  },
  {
    deep: true,
  },
);

const autoWidthKey = ref('');
onMounted(() => {
  let chromeWidthChange = debounce(() => {
    autoWidthKey.value = window.innerWidth;
  }, 100);
  window.addEventListener('resize', chromeWidthChange);
});
</script>

<style lang="less">
html,
body {
  height: 100%;
}

#icon_module {
  display: none;
}
#iconDiv1 {
  display: none;
}

.centerpa {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hoverbig {
  cursor: pointer;
  transition: all 0.1s;
  &:hover {
    transform: scale(1.1);
  }
}

.n-slider {
  &.n-slider--disabled {
    opacity: 1 !important;
  }
  .n-slider-handles {
    display: none;
  }

  .n-slider-marks {
    font-size: 20px;
    line-height: 1.5;
    top: -60px !important;
    color: rgba(18, 101, 234, 1) !important;
    .n-slider-mark {
      white-space: pre-wrap !important;
    }
  }
  .n-slider-rail {
    .n-slider-rail__fill {
      background-color: rgba(57, 122, 255, 1) !important;
    }
    .n-slider-dots {
      .n-slider-dot {
        width: 20px !important;
        height: 20px !important;
        @media screen and (max-width: 900px) {
          width: 40px !important;
          height: 40px !important;
        }
        border: none !important;
        background-color: rgba(223, 223, 223, 1) !important;
        &.n-slider-dot--active {
          background-color: rgba(57, 122, 255, 1) !important;
        }
      }
    }
  }
}
.el-slider {
  .el-slider__button-wrapper {
    display: none;
  }
  .el-slider__bar {
    background-color: rgba(57, 122, 255, 1) !important;
  }
  .el-slider__stop {
    background-color: red !important;
    // background-color: #e4e7ed !important;
    top: -4px;
    width: 20px;
    height: 20px;
  }
}

#app {
  color: rgba(37, 43, 58, 1);
  font-weight: 400;
  // font-size: 22px;
  font-size: 20px;
  min-width: 900px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
}

// 手机端样式
@media screen and (max-width: 900px) {
  #app {
    font-size: 40px;
  }
  .maxxlbig {
    transform: scale(1.2);
  }
  .maxxlbig3 {
    transform: scale(1.3);
  }
  .maxxlbig4 {
    transform: scale(1.4);
  }
  .maxxlbig5 {
    transform: scale(1.5);
  }
}

.switchLangBox {
  > div {
    height: 50px;
    @apply maxxl:h-[100px];
    @apply flex justify-center items-center;
    font-size: 16px;
    @apply maxxl:text-[40px];
    cursor: pointer;
    &:hover {
      background: rgba(244, 244, 244, 1);
    }
  }
}

.rotate {
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gray {
  background-color: rgba(244, 244, 244, 1);
}

.blue {
  color: rgba(67, 103, 236, 1);
}

.swiper-scrollbar {
  height: 14px !important;
  .swiper-scrollbar-drag {
    background: rgba(63, 112, 241, 1) !important;
  }
}

.swiper-button-prev {
  left: 100px !important;
}
.swiper-button-next {
  right: 100px !important;
}
.swiper {
  &.outsit {
    padding-bottom: 60px;
  }
  &.rightcircle {
    .swiper-pagination {
      text-align: right;
      padding-right: 100px;
    }
  }
  &.inlineclick {
    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 100px;
        height: 10px;
        border-radius: 5px;
        background-color: rgba(211, 211, 211, 1);
        margin-left: 10px;
        &:first-child {
          margin-left: 0;
        }
        &.swiper-pagination-bullet-active {
          background-color: rgba(67, 103, 236, 1);
        }
      }
    }
  }
}
.bigTitle {
  font-size: 56px;
  @apply maxxl:text-[70px];
  font-weight: bold;
  text-align: center;
  letter-spacing: 7px;
  span {
    font-size: 32px;
    @apply maxxl:text-[45px];
  }
}

.blue {
  color: rgba(67, 115, 241, 1);
}

.whiteBg {
  background: white;
}

.blueBg {
  background: rgba(228, 238, 255, 1);
}

.bgImg {
  background-position: center;
  // background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
}

.blueBorderButton {
  border-bottom: 2px solid rgba(4, 67, 227, 1);
}

.textBlue {
  color: rgba(67, 103, 236, 1);
}

.customLeftIcon {
  @apply flex justify-center items-center;
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgba(134, 134, 134, 0.7);
  box-shadow: 5px 0px 27px 0px #96aecf;
  color: white;
  cursor: pointer;
  z-index: 1;
}
.customRightIcon {
  @apply flex justify-center items-center;
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgba(134, 134, 134, 0.7);
  box-shadow: 5px 0px 27px 0px #96aecf;
  color: white;
  cursor: pointer;
  z-index: 1;
}

.n-carousel.n-carousel--show-arrow.n-carousel--bottom .n-carousel__dots {
  transform: translateX(0);
  left: initial !important;
  bottom: 18px;
  right: 50px;
}
.n-carousel .n-carousel__dots.n-carousel__dots--dot .n-carousel__dot.n-carousel__dot--active {
  background-color: rgba(67, 103, 236, 1) !important;
}

.smallTitle {
  font-size: 40px;
  @apply maxxl:text-[60px];
  font-weight: bold;
  text-align: center;
  letter-spacing: 7px;
}

.introTitle {
  color: rgba(61, 61, 61, 1);
  font-size: 16px;
  @apply maxxl:text-[34px];
  text-align: center;
}

.minTitle {
  font-size: 28px;
  @apply maxxl:text-[40px];
  font-weight: bold;
  text-align: center;
  letter-spacing: 7px;
}

.carouselRadiu {
  .el-carousel__indicators {
    position: absolute;
    right: 10px;
    bottom: 10px;
    left: initial;
    transform: translateX(0);
    .el-carousel__indicator {
      &.is-active {
        .el-carousel__button {
          background-color: rgba(67, 103, 236, 1);
        }
      }
      .el-carousel__button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
  }
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.25em;
  fill: currentColor;
  overflow: hidden;
}

.pageFull {
  @apply flex flex-col relative flex-auto;
  // height: 100%;
}
</style>
